import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc, query, where, writeBatch } from 'firebase/firestore';

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [clients, setClients] = useState([]);
  const [parks, setParks] = useState([]);
  const [settings, setSettings] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setClients([]);
  }, [user?.selectedCity]);

  // useEffect(() => {
  //   async function copyDoc() {
  //     // Copy lisbon/airpark to porto/airpark 
  //     const lisbonAirparkRef = doc(db, 'lisbon/airpark/');
  //     const portoAirparkRef = doc(db, 'porto/airpark/');
  //     const lisbonAirparkSnap = await getDoc(lisbonAirparkRef);
  //     const portoAirparkSnap = await getDoc(portoAirparkRef);
  //     if (lisbonAirparkSnap.exists()) {
  //       const lisbonAirparkData = lisbonAirparkSnap.data();
  //       const portoAirparkData = portoAirparkSnap.data();
  //       const alocations = lisbonAirparkData.alocations;
  //       // update the doc only the alocations
  //       const batch = writeBatch(db);
  //       batch.set(portoAirparkRef, { ...portoAirparkData, alocations });
  //       await batch.commit();
  //     }
  //   }
  //   copyDoc();
  // }, []);


  useEffect(() => {
    const updateClientsWithAlocation = async () => {
      // Filter clients that meet the criteria
      const newClients = clients.filter(
        (ele) =>
          ele?.status !== "entregue" &&
          ele?.status !== "cancelado" &&
          !ele?.alocation
      );

      // Initialize Firestore batch
      const batch = writeBatch(db);

      for (const client of newClients) {
        // Generate a new allocation for the client
        const newAlocation = await getNewAlocation(client);

        // Reference the client's document in Firestore
        const clientDocRef = doc(db, `${client.city.toLowerCase()}/${client.parkBrand.toLowerCase()}/clients/${client.idClient}`);

        // Add the update operation to the batch
        batch.update(clientDocRef, { alocation: newAlocation });
      }

      // Commit the batch to apply all updates
      await batch.commit();
      console.log("All documents updated successfully");
    };

    if (clients.length > 0) {
      updateClientsWithAlocation().catch((error) =>
        console.error("Error updating documents:", error)
      );
    }
  }, [clients]); // eslint-disable-line react-hooks/exhaustive-deps

  const getParkingType = (parkingType) => {
    switch (parkingType) {
      case "coberto":
        return "covered";
      case "descoberto":
        return "uncovered";
      case "indoor":
        return "indoor";
      default:
        return "uncovered";
    }
  }

  const getNewAlocation = async (client) => {
    const city = collection(db, client.city.toLowerCase());
    const parkBrand = client.parkBrand;
    const docRef = doc(city, parkBrand);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const alocations = docSnap.data().alocations;

      // Get the range for the specified parking type
      let parkingType = client?.parking?.en?.toLowerCase();
      if (!alocations[parkingType]) {
        parkingType = client?.parkingType?.toLowerCase();
      }
      if (!alocations[parkingType]) {
        parkingType = getParkingType(parkingType);
      }
      const range = alocations[parkingType];
      if (!range) throw new Error(`Invalid parking type: ${parkingType}`);

      let newAlocation = null;

      while (!newAlocation) {
        // Generate a random allocation within the range
        const randomAlocation = Math.floor(
          Math.random() * (range.max - range.min + 1) + range.min,
        );

        // Query to check if the allocation is already taken
        const clientsCollection = collection(
          db,
          `${client.city.toLowerCase()}/${client.parkBrand.toLowerCase()}/clients`,
        );
        const q = query(
          clientsCollection,
          where("alocation", "==", randomAlocation),
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          newAlocation = randomAlocation;
        } else {
          // Check if the docs have stats of "entregue" or "cancelado", if so, we can use this alocation
          const docs = querySnapshot.docs;
          const validDocs = docs.filter(
            (doc) => doc.data().stats !== "entregue" && doc.data().stats !== "cancelado",
          );
          if (validDocs.length === 0) {
            newAlocation = randomAlocation;
          }
        }
      }

      return newAlocation;
    }

    return "N/A";
  };

  const fetchInfo = async () => {
    // Parks
    try {
      let cityData = {};
      const citiesDocRef = doc(db, 'settings', 'cities');
      const citiesDocSnap = await getDoc(citiesDocRef);
      if (citiesDocSnap.exists()) {
        const citiesData = citiesDocSnap.data();
        for (const brand of citiesData[user?.selectedCity]) {
          if (!user?.parks.includes(brand.toLowerCase())) continue;
          // Parks
          const brandDocSnap = await getDoc(doc(db, user?.selectedCity, brand));
          if (!brandDocSnap.exists()) continue;
          const brandData = brandDocSnap.data()?.parks;
          let parksData = {};
          for (const park of brandData) {
            parksData[park.name] = park;
          }
          cityData[brand] = parksData;
        }
      }
      cityData = Object.keys(cityData).filter((ele) => user?.parks.includes(ele.toLowerCase())).reduce((obj, key) => {
        obj[key] = cityData[key];
        return obj;
      }, {});
      if (!Object.keys(cityData).length) setParks({});
      else setParks(cityData);
    } catch (error) {
      console.error("Fetch error:", error);
    }

    // Settings
    const allSettings = {};
    let settingsDocRef = doc(db, 'settings', 'tipoDeCancelamento');
    let settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['tipoDeCancelamento'] = settingsData;
    }
    settingsDocRef = doc(db, 'settings', 'tipoDePagamento');
    settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['tipoDePagamento'] = settingsData;
    }
    settingsDocRef = doc(db, 'settings', 'tipoDeOcorrencia');
    settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['tipoDeOcorrencia'] = settingsData;
    }
    settingsDocRef = doc(db, 'settings', 'procedimentos');
    settingsDocSnap = await getDoc(settingsDocRef);
    if (settingsDocSnap.exists()) {
      const settingsData = settingsDocSnap.data();
      allSettings['procedimentos'] = settingsData;
    }
    settingsDocRef = await getDoc(doc(db, 'settings', 'cities'));
    if (settingsDocRef.exists()) {
      const settingsData = settingsDocRef.data();
      allSettings['cities'] = Object.keys(settingsData);
      allSettings['brands'] = settingsData[user?.selectedCity];
    }
    setSettings(allSettings);
  };

  async function getClients(key, filtros) {
    try {
      // setClients(testData);
      let reservas = [];
      if (!settings) return;
      setIsLoading(true);
      for (const brand of settings['brands']) {
        if (!user?.parks.includes(brand.toLowerCase())) continue;
        // Clients
        const clientsRef = collection(db, `${user?.selectedCity}/${brand}/clients`);
        const q = query(clientsRef, where(key, 'in', filtros));
        const querySnapshot = await getDocs(q);
        const brandBooks = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          idClient: doc.id?.toString(),
        }));
        reservas = [...reservas, ...brandBooks];
      }
      // Filtrar por idClient que nao existe em clients
      reservas = reservas.filter((ele) => !clients.find((cli) => cli?.idClient === ele?.idClient));
      reservas = reservas.filter((ele) => user?.parks.includes((ele?.parkBrand).toLowerCase()));
      setClients([...clients, ...reservas]);
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    async function fetchUsers() {
      // Users
      if (user?.type === 'Admin' && user?.selectedCity) {
        const usersRef = collection(db, `users`);
        const usersQuerySnapshot = await getDocs(usersRef);
        const usersData = usersQuerySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const filteredUsers = usersData.filter((ele) => ele?.cities?.includes(user?.selectedCity));
        setUsers(filteredUsers);
      }
    }

    if (user && user?.type !== 'inactive' && user?.selectedCity) {
      fetchInfo();
      fetchUsers();
    }
  }, [user, user?.selectedCity]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClientsContext.Provider value={{
      user,
      setUser,
      users,
      setUsers,
      clients,
      setClients,
      parks,
      setParks,
      settings,
      setSettings,
      getClients,
      fetchInfo,
      isLoading,
    }}>
      {children}
    </ClientsContext.Provider>
  );
};
