import React, { useContext, useEffect, useState } from "react";
import UserCard from "../components/userCard";
import DownloadIcon from "../../content/imgs/icons/download.png";
import { useNavigate } from "react-router-dom";
import { exportExcel } from "../components/export";
import Loading from "../components/loading";
import { ClientsContext } from "../../contexts/Context";

export default function User() {
  const navigate = useNavigate();
  const context = useContext(ClientsContext);

  const [users, setUsers] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [selectType, setSelectType] = useState("all");
  const [toggle, setToggle] = useState(0);
  const toggleList = ["left-0", "left-1/2"];

  useEffect(() => {
    if (context?.users) {
      let filteredUsers = context?.users.filter((user: any) =>
        toggle === 0 ? user.type !== "inactive" : user.type === "inactive"
      );
      // Filter by search when search is not empty
      if (search.trim() !== "") {
        filteredUsers = filteredUsers.filter((user: any) => {
          const searchTerm = search.toLowerCase();
          return (
            (user?.name).toString().toLowerCase().includes(searchTerm) ||
            (user?.email).toString().toLowerCase().includes(searchTerm)
          );
        });
      }
      // Filter by brand
      if (selectType !== "all") {
        filteredUsers = filteredUsers.filter((user:any) => {
          return user?.type.toLowerCase() === selectType.toLowerCase();
        });
      }
      setUsers(filteredUsers);
    }
  }, [context?.users, toggle, search, selectType]);

  function handleExport() {
    exportExcel(users, "utilizadores");
  }

  if (!users) {
    return <Loading />;
  }

  return (
    <>
      <button
        onClick={() => navigate("/home")}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col items-center w-full gap-6 my-20">
        <h1 className="text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem]">
          UTILIZADORES
        </h1>
        <div className="flex w-full gap-4">
          {/* <button onClick={() => navigate('/users/create')} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white'>+ NOVO USER</button> */}
          <button
            onClick={handleExport}
            className="w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black flex gap-2 items-center justify-center"
          >
            EXPORTAR
            <img src={DownloadIcon} alt="icon" className="w-6" />
          </button>
        </div>
        <div className="relative flex items-center w-full h-8 border-2 border-black rounded-full justify-evenly">
          <div
            className={`absolute top-0 ${toggleList[toggle]} h-full w-1/2 bg-[var(--yellow)] rounded-full z-[-1] transition-all duration-300`}
          ></div>
          <p
            onClick={() => setToggle(0)}
            className="w-1/2 text-[.8rem] text-center cursor-pointer"
          >
            Ativos
          </p>
          <p
            onClick={() => setToggle(1)}
            className="w-1/2 text-[.8rem] text-center cursor-pointer"
          >
            Inativos
          </p>
        </div>
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-left text-[var(--primary)] font-bold w-full">
            Filtrar:
          </p>
          <select
            name="brand"
            value={selectType}
            className="select"
            onChange={(e) => setSelectType(e.target.value)}
          >
            <option value="all">Todos os tipos</option>
            <option value={"Junior"} className="text-black bg-white">
              Júnior
            </option>
            <option value={"Condutor"} className="text-black bg-white">
              Condutor
            </option>
            <option value={"Chefe"} className="text-black bg-white">
              Chefe de Turno
            </option>
            <option value={"Supervisor"} className="text-black bg-white">
              Supervisor
            </option>
            <option value={"Admin"} className="text-black bg-white">
              Admin
            </option>
          </select>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="| PESQUISA"
            className="input"
          />
        </div>
        <div className="flex flex-col w-full gap-2 pb-8">
          {users &&
            users.map((user: any, index: number) => {
              return (
                <UserCard
                  key={index}
                  href={"user"}
                  username={user.email}
                  name={user.name}
                  type={user.type}
                  points={user.pontos}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
