import React, { useContext, useEffect, useRef, useState } from "react";
import Card from "../components/clientCard";
import DatePainel from "../components/date";
// import Search from '../components/search';
import { ClientsContext } from "../../contexts/Context";
import { useDate, formatDateSimple } from "../../contexts/DateContext";
import { useNavigate } from "react-router-dom";
import PrintBookingsComponent from "../../components/bookingPage";
import PrintSimpleBookingsComponent from "../../components/simpleBookingPage";
import { handlePrint } from "../../components/handlePrint";

export default function Recolhas() {
  const navigate = useNavigate();
  const { date } = useDate();

  const [cards, setCards] = useState<any>([]);
  const [search, setSearch] = useState("");
  const context = useContext(ClientsContext);
  const [selectBrand, setSelectBrand] = useState("all");
  const [toggle, setToggle] = useState(0);
  const toggleList = ["left-0", "left-1/2"];
  const printRef = useRef<HTMLDivElement | null>(null);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsReadyToPrint(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (
      !context?.clients?.some((client: any) =>
        ["reservado", "em recolha"].includes(client?.stats)
      )
    ) {
      getClients(["reservado", "em recolha"]);
    }
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      context?.clients &&
      context?.clients.length > 0 &&
      context?.clients.some(
        (client: any) =>
          client?.stats === "reservado" || client?.stats === "em recolha"
      )
    ) {
      let newCards = context.clients.filter((card: any) => {
        if (toggle === 0) {
          return card?.stats === "reservado";
        }
        return card?.stats === "em recolha";
      });

      let simplifiedDate = formatDateSimple(date);

      // Filter by date when search is empty
      if (search.trim() === "" && toggle !== 1) {
        newCards = newCards.filter((card: any) => {
          return (
            getMonthAndDay(card?.checkIn.split(", ")[0]) === simplifiedDate
          );
        });
      }

      newCards = newCards.sort((a: any, b: any) => {
        const timeA = a?.checkIn.split(" ")[1];
        const timeB = b?.checkIn.split(" ")[1];
        return timeA.localeCompare(timeB);
      });

      // Filter by search when search is not empty
      if (search.trim() !== "") {
        newCards = newCards?.filter((card: any) => {
          const searchTerm = search?.toLowerCase();
          return (
            card?.licensePlate
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm) ||
            card?.name?.toString().toLowerCase().includes(searchTerm) ||
            card?.alocation?.toString().toLowerCase().includes(searchTerm)
          );
        });
      }
      // Filter by brand
      if (selectBrand !== "all") {
        newCards = newCards.filter((card: any) => {
          return card?.parkBrand.toLowerCase() === selectBrand.toLowerCase();
        });
      }
      setCards(newCards);
    }
  }, [date, context?.clients, search, selectBrand, toggle, context]);

  function getMonthAndDay(date: string) {
    return `${date.split("/")[0]}/${date.split("/")[1]}`;
  }

  async function getClients(newStats: string[]) {
    await context?.getClients("stats", newStats);
  }

  function printBookings() {
    if (!isReadyToPrint) return;

    if (printRef) {
      return handlePrint(printRef);
    }
  }

  if (!context) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        onClick={() => navigate("/home")}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          RECOLHAS
        </h1>
        <DatePainel />
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-left text-[var(--primary)] font-bold w-full">
            Filtrar:
          </p>
          <select
            name="brand"
            value={selectBrand}
            className="select"
            onChange={(e) => setSelectBrand(e.target.value)}
          >
            <option value="all">Todas as marcas</option>
            <option value="airpark">Airpark</option>
            <option value="redpark">Redpark</option>
            <option value="skypark">Skypark</option>
          </select>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="| PESQUISA"
            className="input"
          />
        </div>
        <div className="relative flex items-center w-full h-8 border-2 border-black rounded-full justify-evenly">
          <div
            className={`absolute top-0 ${toggleList[toggle]} h-full w-1/2 bg-[var(--yellow)] rounded-full z-[-1] transition-all duration-300`}
          ></div>
          <p
            onClick={() => setToggle(0)}
            className="w-1/2 text-[.8rem] text-center cursor-pointer"
          >
            Reservados
          </p>
          <p
            onClick={() => setToggle(1)}
            className="w-1/2 text-[.8rem] text-center cursor-pointer"
          >
            Em Recolha
          </p>
        </div>
        {context?.isLoading ? (
          <p className="text-center">A carregar...</p>
        ) : (
          <>
            {(context?.user?.type === "Admin" ||
              context?.user?.type === "SuperVisor") && (
              <div>
                <button
                  onClick={printBookings}
                  className="w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black"
                >
                  IMPRIMIR
                </button>
              </div>
            )}
            <div className="absolute translate-x-[-200vw] translate-y-[-200vh]" ref={printRef}>
              {cards && cards.length !== 0 ? (
                cards.map((card: any, index: number) => {
                  return (
                    <div key={`print-${index}`}>
                      <PrintBookingsComponent card={card} />
                      <PrintSimpleBookingsComponent card={card} />
                    </div>
                  );
                })
              ) : (
                <p className="w-full text-center">Nenhum resultado</p>
              )}
            </div>
            {cards && cards.length > 0 && (
              <p className="w-full font-bold text-center">
                Total: {cards.length} recolha{cards.length > 1 && "s"}
              </p>
            )}
            <div className="flex flex-col w-full gap-2">
              {cards && cards.length > 0 ? (
                cards.map((card: any, index: any) => {
                  return (
                    <Card
                      key={card.idClient}
                      client={card}
                      href={"recolhas"}
                      type={"recolhas"}
                    />
                  );
                })
              ) : (
                <p className="text-center">Nenhuma recolha encontrada</p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
