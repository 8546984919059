export const handlePrint = (printRef: any): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!printRef.current) {
      reject(new Error("Print reference not found"));
      return;
    }

    const printContents = printRef.current.innerHTML;
    const printWindow = window.open("", "", "width=800,height=600");

    if (!printWindow) {
      reject(
        new Error(
          "Failed to open print window. Pop-up blocker might be preventing it."
        )
      );
      return;
    }

    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules || [])
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          return "";
        }
      })
      .join("\n");

    const inlineStyles = `<style>${styles}</style>`;
    printWindow.document.head.insertAdjacentHTML("beforeend", inlineStyles);

    printWindow.document.write(`
      <html>
      <head>
        <title>Print Bookings</title>
        <style>
          ${styles}
          @media print {
            body {
              -webkit-print-color-adjust: exact !important;
            }
            .page-break {
              page-break-after: always;
            }
          }
        </style>
      </head>
      <body>
        ${printContents}
      </body>
      </html>
    `);

    printWindow.document.close();

    setTimeout(() => {
      printWindow.focus();
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
        resolve();
      };
    }, 500);
  });
};
