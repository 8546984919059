import React, { useContext, useEffect, useState } from "react";
import Card from "../components/clientCard";
import { ClientsContext } from "../../contexts/Context";
import { useNavigate } from "react-router-dom";
import { exportExcel } from "../components/export";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

export default function Recolhas() {
  const navigate = useNavigate();
  const context = useContext(ClientsContext);

  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const defaultDateString = defaultDate.toISOString().split("T")[0];

  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const [selectStats, setSelectStats] = useState("recolhido");
  const [selectBrand, setSelectBrand] = useState("all");
  const [beginDate, setBeginDate] = useState(defaultDateString);
  const [isLoading, setIsLoading] = useState(true);
  const [showOcorrences, setShowOcorrences] = useState(false);
  const [type, setType] = useState("");

  useEffect(() => {
    if (context?.user) {
      setType(context?.user.type);
    }
  }, [context?.user]);

  useEffect(() => {
    getClients(["recolhido", "em entrega", "pendente", "em movimento"]);
  }, [context]);

  async function getClients(newStats: string[]) {
    setIsLoading(true);
    if (
      !context?.clients?.some((client: any) => newStats.includes(client?.stats))
    ) {
      await context?.getClients("stats", newStats);
    }
    if (selectStats === "all") {
      await context?.getClients("stats", newStats);
    }
    setIsLoading(false);
  }

  function handleExport() {
    const updatedCards = cards.map((card: any) => {
      const updatedExtraServices = card.extraServices
        .map((service: any) => service.pt)
        .join(", ");
      return {
        ...card,
        extraServices: updatedExtraServices,
      };
    });
    exportExcel(updatedCards, selectStats);
  }

  async function handleExportAll() {
    let allHistoryEntries: any[] = [];

    for (const card of cards as Array<any>) {
      try {
        const historyPath = `${
          card.city
        }/${card.parkBrand.toLowerCase()}/clients/${card.idClient}/history`;
        const historyRef = collection(db, historyPath);
        const historySnapshot = await getDocs(historyRef);

        if (!historySnapshot.empty) {
          historySnapshot.forEach((doc) => {
            allHistoryEntries.push({
              city: card.city,
              parkBrand: card.parkBrand,
              idClient: card.idClient,
              ...doc.data(),
              extraServices: doc.data().extraServices
                ? doc
                    .data()
                    .extraServices.map((service: any) => service.pt)
                    .join(", ")
                : "",
            });
          });

          // Add a separator row after each card's history
          allHistoryEntries.push({});
        }
      } catch (error) {
        console.error(
          `Error fetching history for card ${card.idClient}:`,
          error
        );
      }
    }

    if (allHistoryEntries.length > 0) {
      exportExcel(allHistoryEntries, selectStats);
    } else {
      alert("Não existem registos para exportar.");
    }
  }

  useEffect(() => {
    if (context?.clients && context?.clients.length > 0) {
      let newCards = context.clients;
      // Search filter
      if (search.trim() !== "") {
        newCards = newCards.filter((card: any) => {
          const searchTerm = search.toLowerCase();
          return (
            card?.name.toString().toLowerCase().includes(searchTerm) ||
            card?.licensePlate.toString().toLowerCase().includes(searchTerm) ||
            card?.alocation?.toString().toLowerCase().includes(searchTerm)
          );
        });
      }
      // Filter by stats
      if (selectStats !== "all") {
        newCards = newCards.filter((card: any) => {
          return card?.stats === selectStats;
        });
      }
      // Filter by brand
      if (selectBrand !== "all") {
        newCards = newCards.filter((card: any) => {
          return card?.parkBrand.toLowerCase() === selectBrand.toLowerCase();
        });
      }

      // Filter by ocorrences
      if (showOcorrences) {
        newCards = newCards.filter((card: any) => {
          return card?.ocorrenceType;
        });
      }

      // Filter by date
      if (!search && beginDate) {
        const [year, month, day] = beginDate
          .split("-")
          .map((num) => parseInt(num, 10));
        const beginDateObj = new Date(year, month - 1, day);
        newCards = newCards.filter((card: any) => {
          if (!card?.bookingDate) return true;
          const [datePart, timePart] = card?.bookingDate.includes(",")
            ? card.bookingDate.split(", ")
            : card.bookingDate.split(" ");
          const [day, month, year] = datePart
            .split("/")
            .map((num: any) => parseInt(num, 10));
          const [hours, minutes] = timePart
            .split(":")
            .map((num: any) => parseInt(num, 10));
          const bookingDateObj = new Date(year, month - 1, day, hours, minutes);
          return bookingDateObj >= beginDateObj;
        });
      }
      setCards(newCards.reverse());
    }
  }, [context?.clients, search, selectStats, selectBrand, beginDate]);

  useEffect(() => {
    if (selectStats === "all") {
      getClients([
        "reservado",
        "recolhido",
        "entregue",
        "cancelado",
        "em recolha",
        "em entrega",
        "em movimento",
        "pendente",
      ]);
    } else if (selectStats === "reservado" && !context?.clients?.some((client: any) => client?.stats === "reservado")) {
      getClients(["reservado", "em recolha"]);
    } else if (selectStats === "recolhido" && !context?.clients?.some((client: any) => client?.stats === "recolhido")) {
      getClients(["recolhido", "em entrega", "pendente", "em movimento"]);
    } else if (selectStats === "entregue" && !context?.clients?.some((client: any) => client?.stats === "entregue")) {
      getClients(["entregue"]);
    } else if (selectStats === "cancelado" && !context?.clients?.some((client: any) => client?.stats === "cancelado")) {
      getClients(["cancelado"]);
    }
  }, [selectStats]);

  if (!context) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        onClick={() => navigate("/home")}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          CONSULTA
        </h1>
        <p className="text-center flex gap-10 text-[var(--black)] font-mybold justify-center">
          TODOS OS REGISTOS
        </p>
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-left text-[var(--primary)] font-bold w-full">
            Filtrar:
          </p>
          <select
            name="stats"
            value={selectStats}
            className="select"
            onChange={(e) => setSelectStats(e.target.value)}
          >
            <option value="reservado">Reservados</option>
            <option value="recolhido">Recolhidos</option>
            <option value="entregue">Entregues</option>
            <option value="cancelado">Cancelados</option>
          </select>
          <select
            name="brand"
            value={selectBrand}
            className="select"
            onChange={(e) => setSelectBrand(e.target.value)}
          >
            <option value="all">Todas as marcas</option>
            {
              context?.user?.parks?.map((brand: string) => (
                <option key={brand} value={brand} className="capitalize">
                  {brand}
                </option>
              ))
            }
          </select>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="| PESQUISA"
            className="input"
          />
          <div className="flex items-center w-full gap-4">
            <p className="w-full">Reservas desde o dia:</p>
            <input
              value={beginDate}
              onChange={(e) => setBeginDate(e.target.value)}
              type="date"
              className="input"
            />
          </div>
          {type === "Admin" && (
            <div className="flex justify-between w-full">
              <p>Reservas com ocorrência: </p>
              <input
                type="checkbox"
                checked={showOcorrences}
                onChange={() => setShowOcorrences(!showOcorrences)}
                className="w-6 h-6 accent-[var(--primary)]"
              />
            </div>
          )}
        </div>
        {(type === "Admin" || type === "Admin") && (
          <>
            <div className="flex w-full gap-4">
              <button
                onClick={() => navigate("/import")}
                className="w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white flex gap-2 items-center justify-center"
              >
                ADICIONAR RESERVA
              </button>
            </div>
            <div className="flex w-full gap-4">
              <button
                onClick={handleExport}
                className="w-full text-[.9rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black flex gap-2 items-center justify-center"
              >
                EXPORTAR RESERVAS
              </button>
              <button
                onClick={handleExportAll}
                className="w-full text-[.9rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black flex gap-2 items-center justify-center"
              >
                EXPORTAR HISTORICO
              </button>
            </div>
          </>
        )}
        {cards && cards.length > 0 && (
          <p className="w-full font-bold text-center">Total: {cards.length}</p>
        )}
        <div className="flex flex-col w-full gap-2">
          {context?.isLoading ? (
            <p className="text-center">A carregar...</p>
          ) : cards && cards.length > 0 ? (
            cards.slice(0, 50).map((card: any, index: any) => {
              return (
                <Card
                  key={index}
                  client={card}
                  href={"consulta"}
                  type={"consulta"}
                />
              );
            })
          ) : (
            <p className="text-center">Nenhuma reserva encontrada</p>
          )}
        </div>
      </div>
    </>
  );
}
