import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../components/clientCard';
import { updateClient } from '../../server';
import Dialog from '../components/alertDialog';
import { auth } from '../../firebase';
import { ClientsContext } from '../../contexts/Context';
import Loading from '../components/loading';

export default function Movimentacao() {
    const { id = 0 } = useParams();
    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [parkData, setParkData] = useState<any>(null)
    const [parksList, setParksList] = useState<any>(null)
    const [rows, setRows] = useState<any>(null)
    const [spots, setSpots] = useState<any>(null)

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const [client, setClient] = useState<any>(null);
    const [veiculo, setVeiculo] = useState('');
    const [obs, setObs] = useState('');
    const [condutor, setCondutor] = useState('');
    const [parque, setParque] = useState('');
    const [fila, setFila] = useState('');
    const [lugar, setLugar] = useState('');
    const [novoParque, setNovoParque] = useState('');
    const [novaFila, setNovaFila] = useState('');
    const [novoLugar, setNovoLugar] = useState('');
    const [location, setLocation] = useState<any>(null);

    useEffect(() => {
        async function getClientCard() {
            if (!context?.clients || context?.clients.length === 0
                || !context?.clients.some((client:any) => client?.idClient === id.toString())) {
                    context?.getClients('idClient', [id.toString()]);
                    return ;
                }
            const data = (context?.clients).filter((client: any) => (client?.idClient).toString() === id.toString())
            if (data.length === 1) setClient(data[0]);
        }
        getClientCard();
    }, [id, context?.clients, context]);

    useEffect(() => {
        async function fetchData() {
            if (context?.parks) {
                const parks = context.parks;
                const list = Object.keys(parks).reduce((acc: any, brand: string) => {
                    return [...acc, ...Object.keys(parks[brand]).map((park: string) => `${brand}:${park}`)];
                }, []);
                setParksList([...list]);
                setParkData(parks);
            }
            setVeiculo(client?.carInfo);
            setParque(client?.park);
            setFila(client?.row);
            setLugar(client?.spot);
            setCondutor(context?.user.email || '');
            if (localStorage.getItem('formData')) fetchPrevData();
        }
        if (auth?.currentUser && client)
            fetchData();
    }, [client, context?.user.email, context?.parks]);

    useEffect(() => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            () => {
                alert('Unable to retrieve your location');
            }
        );
    }, []);

    function fetchPrevData() {
        const data = localStorage.getItem('formData');
        if (data) {
            const parsedData = JSON.parse(data);
            setNovoParque(parsedData?.parque || '');
            setNovaFila(parsedData?.fila || '');
            setNovoLugar(parsedData?.lugar || '');
            setObs(parsedData?.obs || '');
        }
        localStorage.removeItem('formData');
    }

    function ocorrencia() {
        localStorage.setItem('formData', JSON.stringify({
            obs: obs,
            parque: novoParque,
            fila: novaFila,
            lugar: novoLugar
        }))
        navigate(`/ocorrencias/${id}`);
    }

    function formatDate(isoDateString: string) {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year}, ${hours}:${minutes}`;
    }

    function isBeingUsed() {
        let cardsObject = context.clients.filter((card: any) => card?.stats === 'recolhido' && card?.park && card?.park === novoParque);
        return cardsObject.some((card: any) => card.row === novaFila && card.spot === novoLugar);
    }

    function checkIsSpotAvailable() {
        if (parkData && novoParque && novaFila && novoLugar) {
            const brand = novoParque.split(':')[0];
            const park = novoParque.split(':')[1];
            const row = novaFila;
            const spot = novoLugar;
            return !(parkData[brand][park].unavailableSpots.some((ele: any) => (ele.row).toString() === row && (ele.spot).toString() === spot) || isBeingUsed());
        }
        return true;
    }

    async function movimentar(estado:string) {
        if (context?.user.blockedBy && context?.user.blockedBy !== client.licensePlate) {
            openDialog(`Utilizador bloqueado, tem o carro ${context?.user?.blockedBy} em movimento.`, () => null)
            return;
        }
        if (novoParque === '') {
            openDialog('Escolha pelo menos o parque destino.', () => null)
            return;
        }
        if (!checkIsSpotAvailable()) {
            openDialog(`Lugar indisponível. Selecione outra fila-lugar.`, () => null)
            return;
        }
        const data = { ...client };
        const now = formatDate((new Date()).toISOString());
        data['park'] = novoParque;
        data['row'] = novaFila;
        data['spot'] = novoLugar;
        data['condutorMovimentacao'] = condutor;
        data['stats'] = estado === 'movimentar' ? 'recolhido' : "em movimento";
        data['actionUser'] = context?.user.email || 'unknown';
        data['actionDate'] = now;
        data['action'] = estado === 'movimentar' ? "Movimentação" : "Estado 'em movimento'";
        data['carLocation'] = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;
        try {
            updateClient(context, navigate, data, context?.user.id || 'unknown', estado, openDialog);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    function getCurrentHour() {
        const newDate = new Date();
        return newDate.getHours().toString().padStart(2, '0') + ":" + newDate.getMinutes().toString().padStart(2, '0');
    }

    function changeParque(parqueName: string) {
        if (parqueName) {
            const park = parqueName.split(':')[1];
            const brand = parqueName.split(':')[0];
            setNovoParque(parqueName);
            if (parkData[brand] && Object.keys(parkData[brand]).includes(park)) {
                setRows(parkData[brand][park].rows);
                setSpots(parkData[brand][park].spots);
            }
        } else {
            setNovoParque('');
            setRows(null);
            setSpots(null);
        }
    }


    if (!client || !parkData) {
        return (
            <Loading/>
        )
    }


    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>MOVIMENTAÇÃO</h1>
                <Card
                    client={client}
                    href={null}
                    type={"movimentacao"}
                />
                <div className='flex justify-center'>
                    <p>Reserva de: <span className='font-bold'>{client?.parkBrand}</span></p>
                </div>
                <div className='flex justify-between gap-4'>
                    <div className='w-[55%] flex flex-col justify-between gap-4'>
                        <p>Detalhes Carro:</p>
                        <textarea disabled className="border-black border-2 rounded-[25px] p-2 resize-none h-full bg-[rgba(.5,.5,.5,.1)]" value={veiculo} onChange={(e) => setVeiculo(e.target.value)} placeholder='| Veiculo' />
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        <p>Local park:</p>
                        <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={parque} onChange={(e) => setParque(e.target.value)} placeholder='| Parque' />
                        <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={fila} onChange={(e) => setFila(e.target.value)} placeholder='| Fila' />
                        <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={lugar} onChange={(e) => setLugar(e.target.value)} placeholder='| Lugar' />
                    </div>
                </div>
                <div className='flex justify-between gap-4'>
                    <div className='flex flex-col w-full gap-2'>
                        <div className='flex flex-col gap-2'>
                            <p>Condutor:</p>
                            <input className="input" disabled={context?.user.type !== 'Admin'} type="text" value={condutor} onChange={(e) => setCondutor(e.target.value)} placeholder='| Condutor' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Parque:</p>
                            <select
                                name="parque"
                                value={novoParque}
                                className="select"
                                onChange={(e) => changeParque(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    parksList && parksList.map((park: string, index: number) => {
                                        return (
                                            <option key={index} value={park}>{park}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Fila:</p>
                            <select
                                name="fila"
                                value={novaFila}
                                className="select"
                                onChange={(e) => setNovaFila(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    rows && rows.map((ele: number, index: number) => {
                                        return (
                                            <option key={index} value={ele}>{ele}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <div className='flex flex-col gap-2'>
                            <p>Hora atual:</p>
                            <input className="w-full border-2 border-black rounded-full px-3 py-2 bg-[rgba(.5,.5,.5,.1)]" type="text" value={getCurrentHour()} disabled placeholder='| Hora' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Observação:</p>
                            <input className="input " type="text" value={obs} onChange={(e) => setObs(e.target.value)} placeholder='| Observação' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Lugar:</p>
                            <select
                                name="lugar"
                                value={novoLugar}
                                className="select"
                                onChange={(e) => setNovoLugar(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    spots && spots.map((ele: number, index: number) => {
                                        return (
                                            <option key={index} value={ele}>{ele}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <button onClick={ocorrencia} className='w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black'>OCORRÊNCIA</button>
                    {client?.stats !== "em movimento" && <button onClick={() => movimentar("em movimento")} className='w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-black'>EM MOVIMENTO</button>}
                    <button onClick={() => movimentar("movimentar")} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full  py-2 text-white'>MOVIMENTAR</button>
                </div>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

