import React, { useContext, useEffect, useState } from "react";
import Card from "../components/clientCard";
import { ClientsContext } from "../../contexts/Context";
import { useNavigate } from "react-router-dom";
import { formatDateSimple, useDate } from "../../contexts/DateContext";
import DatePainel from "../components/date";

export default function Recolhas() {
  const navigate = useNavigate();
  const { date } = useDate();

  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const context = useContext(ClientsContext);
  const [selectBrand, setSelectBrand] = useState("all");
  const [selectPark, setSelectPark] = useState("all");
  const [toggle, setToggle] = useState(0);
  const [filterByDate, setFilterByDate] = useState<boolean>(false);
  const toggleList = ["left-0", "left-1/2"];

  function getMonthAndDay(date: string) {
    return `${date.split("/")[0]}/${date.split("/")[1]}`;
  }

  useEffect(() => {
    if (
      context?.clients &&
      context?.clients.length > 0 &&
      context?.clients.some(
        (client: any) =>
          client?.stats === "recolhido" || client?.stats === "em movimento"
      )
    ) {
      let newCards = context.clients.filter((card: any) => {
        if (toggle === 0) return card?.stats === "recolhido"
          ;
        return card?.stats === "em movimento";
      });

      // Filter by search when search is not empty
      if (search.trim() !== "") {
        newCards = newCards.filter((card: any) => {
          const searchTerm = search.toLowerCase();
          return (
            (card?.licensePlate)
              .toString()
              .toLowerCase()
              .includes(searchTerm) ||
            (card?.name).toString().toLowerCase().includes(searchTerm) ||
            (card?.alocation)?.toString().toLowerCase().includes(searchTerm)
          );
        });
      }
      // Filter by brand
      if (selectBrand !== "all") {
        newCards = newCards.filter((card: any) => {
          return card?.parkBrand.toLowerCase() === selectBrand.toLowerCase();
        });
      }
      // Filter by park
      if (selectPark !== "all") {
        newCards = newCards.filter((card: any) => {
          if (!card?.park) return false;
          return (
            card?.park?.split(":")[1]?.toLowerCase() ===
            selectPark.toLowerCase()
          );
        });
        // Sort by row-spot like this:
        newCards = newCards.sort((a: any, b: any) => {
          // Extract row and spot from the card itself
          const rowA = Number(a?.row);
          const spotA = Number(a?.spot);
          const rowB = Number(b?.row);
          const spotB = Number(b?.spot);

          // First compare rows, then spots
          if (rowA === rowB) {
            return spotA - spotB;
          }
          return rowA - rowB;
        });
      }

      // Filter by date when search is empty
      if (date && filterByDate) {
        let simplifiedDate = formatDateSimple(date);
        newCards = newCards.filter((card: any) => {
          return (
            getMonthAndDay(card?.checkOut.split(", ")[0]) === simplifiedDate
          );
        });
      }

      setCards(newCards);
    }
  }, [
    search,
    selectBrand,
    context,
    toggle,
    selectPark,
    date,
    filterByDate
  ]);

  useEffect(() => {
    if (!context?.clients?.some((client: any) => ["recolhido", "em movimento"].includes(client?.stats))) {
      getClients(["recolhido", "em movimento"]);
    }
  }, [context]);

  async function getClients(newStats: string[]) {
    await context?.getClients("stats", newStats);
  }

  if (!context) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        onClick={() => navigate("/home")}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          MOVIMENTAÇÕES
        </h1>
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-left text-[var(--primary)] font-bold w-full">
            Filtrar:
          </p>
          <select
            name="brand"
            value={selectBrand}
            className="select"
            onChange={(e) => setSelectBrand(e.target.value)}
          >
            <option value="all">Todas as marcas</option>
            <option value="airpark">Airpark</option>
            <option value="redpark">Redpark</option>
            <option value="skypark">Skypark</option>
          </select>
          {selectBrand !== "all" && (
            <select
              name="brand"
              value={selectPark}
              className="select"
              onChange={(e) => setSelectPark(e.target.value)}
            >
              <option value="all">Todas os parques</option>
              {context.parks &&
                context.parks[selectBrand] &&
                Object.keys(context.parks[selectBrand]).map(
                  (park: string, index: number) => {
                    return (
                      <option key={index} value={park} className="uppercase">
                        {park}
                      </option>
                    );
                  }
                )}
            </select>
          )}
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="| PESQUISA"
            className="input"
          />
        </div>
        <div className="flex justify-between w-full">
          <p>Data de saída: </p>
          <input
            type="checkbox"
            checked={filterByDate}
            onChange={() => setFilterByDate(!filterByDate)}
            className="w-6 h-6 accent-[var(--primary)]"
          />
        </div>
        <DatePainel />
        <div className="relative flex items-center w-full h-8 border-2 border-black rounded-full justify-evenly">
          <div
            className={`absolute top-0 ${toggleList[toggle]} h-full w-1/2 bg-[var(--yellow)] rounded-full z-[-1] transition-all duration-300`}
          ></div>
          <p
            onClick={() => setToggle(0)}
            className="w-1/2 text-[.8rem] text-center cursor-pointer"
          >
            No parque
          </p>
          <p
            onClick={() => setToggle(1)}
            className="w-1/2 text-[.8rem] text-center cursor-pointer"
          >
            Em movimentação
          </p>
        </div>
        {cards && cards.length > 0 && (
          <p className="w-full font-bold text-center">
            Total: {cards.length} carro{cards.length > 1 && "s"} no sistema
          </p>
        )}
        <div className="flex flex-col w-full gap-2">
          {context?.isLoading ? (
            <p className="text-center">A carregar...</p>
          ) : cards && cards.length > 0 ? (
            cards.slice(0, 20).map((card: any, index: any) => {
              return (
                <Card
                  key={index}
                  client={card}
                  href={"movimentacao"}
                  type={"movimentacao"}
                />
              );
            })
          ) : (
            <p className="text-center">Nenhuma reserva encontrada</p>
          )}
        </div>
      </div>
    </>
  );
}
